import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';
import { uid } from 'react-uid';

import { Paragraphs, Layout, SEO } from 'components';

const ThankYou = ({
  location: { pathname: siteUrl },
  data: {
    nodePage: node,
    nodePage: {
      title: pageTitle,
      field_metatags,
      field_automatic_breadcrumbs,
      relationships: { field_content, field_header_paragraph, field_breadcrumbs },
    },
  },
}) => {
  useEffect(() => {
    if (!node) navigate('/');
  }, []);

  if (!node) return null;
  const paragraphHeader = field_header_paragraph.find(
    (item) => item?.internal?.type === 'paragraph__header_paragraph',
  );
  const background_type = paragraphHeader?.field_background_type || '';

  const title = pageTitle.split('!');
  const firstTitlePart = title[0];
  const secondTitlePart = `! <br/> ${title[1]}`;
  const formattedTitle = `${firstTitlePart} ${title[1] ? secondTitlePart : ''}`;

  return (
    <Layout
      headerData={field_header_paragraph}
      nodeTitle={formattedTitle}
      type={`front thank-you-page ${background_type}`}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
    >
      <SEO
        title={field_metatags?.title || pageTitle}
        description={field_metatags?.description || ''}
        keywords={field_metatags?.keywords || ''}
        robots={field_metatags?.robots}
        currentUrl={siteUrl}
      />
      {field_content.map((item) => (
        <Paragraphs key={uid(item)} data={item} />
      ))}
    </Layout>
  );
};

ThankYou.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      field_automatic_breadcrumbs: PropTypes.bool,
      relationships: PropTypes.shape({
        field_content: PropTypes.array,
        field_header_paragraph: PropTypes.object,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default ThankYou;

export const query = graphql`
  {
    nodePage(path: { alias: { eq: "/thank-you" } }) {
      ...NodeThankYouPage
    }
  }
`;
